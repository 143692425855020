/*------------------------------------
  Icon
------------------------------------*/

.icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: $icon-font-size;
  width: $icon-width;
  height: $icon-height;
  @include border-radius($icon-border-radius);
}

.icon-circle {
  @include border-radius($icon-circle-border-radius);
}

.icon-centered {
  display: flex;
  margin-right: auto;
  margin-left: auto;
}