/*------------------------------------
  Form
------------------------------------*/

.custom-select:focus,
.form-control:focus {
  box-shadow: $input-focus-box-shadow;
}

.form-text {
  color: $form-text-color;
}

.form-link {
  display: inline-block;
  margin-top: $form-link-margin-y;
}

.form-control-single-number {
  font-size: $form-control-single-number-font-size;
  text-align: center;
}