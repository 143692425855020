/*------------------------------------
  Column Divider
------------------------------------*/

.column-divider {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      &#{$infix} {
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          border-left: $column-divider-border-width $column-divider-border-type $column-divider-border-color;
          content: "";
        }
      }
    }
  }
}