/*------------------------------------
  Modal Options
------------------------------------*/

// Cover
.modal-top-cover {
  position: relative;
  overflow: hidden;
  min-height: $modal-top-cover-min-height;
  @include border-top-right-radius($modal-content-inner-border-radius);
  @include border-top-left-radius($modal-content-inner-border-radius);
}

.modal-top-cover-icon,
.modal-top-cover-avatar {
  position: relative;
  z-index: 2;
  margin-top: $modal-top-cover-avatar-margin-y;
}

// Close
.modal-close {
  position: absolute;
  top: $modal-top-cover-close-top-offset;
  right: $modal-top-cover-close-right-offset;
  z-index: $modal-top-cover-close-z-index;
}