/*------------------------------------
  Custom File Button
------------------------------------*/

.custom-file-btn {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.custom-file-btn-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.custom-file-btn-label {
  margin-bottom: 0;
}