/*------------------------------------
  List Separator
------------------------------------*/

.list-separator {
  margin-bottom: 0;

  .list-inline-item {
    position: relative;
    margin-left: 0;
    margin-right: -$list-separator-item-margin-x;

    &:not(:last-child) {
      padding-right: $list-separator-padding-x;

      &::after {
        position: absolute;
        top: calc(50% - #{$list-separator-pseudo-height * 0.5});
        right: #{$list-separator-padding-x * 0.5 - $list-separator-pseudo-width * 0.5};
        width: $list-separator-pseudo-width;
        height: $list-separator-pseudo-height;
        background-color: $list-separator-pseudo-bg-color;
        @include border-radius($list-separator-border-radius);
        content: "";
      }
    }
  }

  .list-separator-link {
    color: $list-separator-link-color;

    &:hover {
      color: $list-separator-link-hover-color;
    }
  }
}