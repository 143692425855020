/*------------------------------------
  Vertical Progress Bar
------------------------------------*/

.progress-vertical {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  background-color: $progress-vertical-bg;
  width: $progress-vertical-width;
  height: $progress-vertical-height;
}