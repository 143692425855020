/*------------------------------------
  Width
------------------------------------*/

.min-h-100 {
  min-height: 100%;
}

// Large Devices
@include media-breakpoint-up(lg) {
  .vh-lg-100 {
    height: 100vh;
  }

  .min-vh-lg-100 {
    min-height: 100vh;
  }
}