/*------------------------------------
  Navbar Extensions
------------------------------------*/

// Input Form
.navbar-input-group {
  min-width: $navbar-input-group-min-width;

  /* clears the 'X' from Internet Explorer */
  input[type=search]::-ms-clear {
    display: none;
    width : 0;
    height: 0;
  }

  input[type=search]::-ms-reveal {
    display: none;
    width : 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
}

// Dropdown Menu
.navbar-dropdown-menu {
  position: absolute !important;
  margin-top: $navbar-dropdown-menu-margin-y;

  &:not(.navbar-dropdown-account) {
    padding: 0;
  }
}

// Account
.navbar-dropdown-account {
  margin-top: $navbar-dropdown-account-margin-y;
}

.navbar-dropdown-account-wrapper {
  display: flex;
  align-items: center;
  color: $navbar-dropdown-account-wrapper-color;
  background-color: $navbar-dropdown-account-wrapper-bg-color;
  padding: $navbar-dropdown-account-wrapper-padding;
  margin-top: $navbar-dropdown-account-wrapper-margin-y;
  margin-bottom: $navbar-dropdown-account-wrapper-margin-y;
  @include border-radius($navbar-dropdown-account-wrapper-border-radius);
  transition: $navbar-dropdown-account-wrapper-transition;

  &:focus,
  &:hover {
    background-color: $navbar-dropdown-account-wrapper-bg-hover-color;
  }
}

.navbar-dropdown-account-body {
  margin-right: $navbar-dropdown-account-wrapper-margin-x;
  margin-left: $navbar-dropdown-account-wrapper-margin-x;
}

// List Group
.navbar-card-list-group {
  margin: $navbar-card-list-group-margin;

  .list-group-item {
    padding-top: $navbar-card-list-group-item-padding-y;
    padding-bottom: $navbar-card-list-group-item-padding-y;
  }
}