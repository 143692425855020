/*------------------------------------
  Close
------------------------------------*/

.close {
  opacity: $close-opacity;

  &:hover {
    color: $close-hover-color;
  }
}