/*------------------------------------
  Input Group Break
------------------------------------*/

@each $breakpoint in map-keys($grid-breakpoints) {
  $next: breakpoint-next($breakpoint, $grid-breakpoints);
  $infix: breakpoint-infix($next, $grid-breakpoints);

  .input-group#{$infix}-down-break {
    @include media-breakpoint-down($breakpoint) {
      display: block;

      > .form-control,
      > .custom-select {
        width: 100%;
      }

      .select2-custom,
      .select2-custom .select2-container {
        width: 100% !important;
      }

      .select2-custom {
        margin-left: 1px;
      }

      > .form-control:first-child,
      > .custom-select:first-child,
      .select2-custom:first-child .select2-selection {
        @include border-top-radius($input-border-radius);
        @include border-bottom-radius(0);
      }

      > .custom-select:not(:first-child):not(:last-child),
      > .form-control:not(:first-child):not(:last-child),
      .select2-custom:not(:first-child):not(:last-child) .select2-selection {
        @include border-radius(0);
      }

      > .custom-select:last-child,
      > .form-control:last-child,
      .select2-custom:last-child .select2-selection {
        @include border-top-radius(0);
        @include border-bottom-radius($input-border-radius);
      }

      .form-control + .form-control,
      .form-control + .custom-select,
      .form-control + .custom-file,
      .form-control-plaintext + .form-control,
      .form-control-plaintext + .custom-select,
      .form-control-plaintext + .custom-file,
      .custom-select + .form-control,
      .custom-select + .custom-select,
      .custom-select + .custom-file,
      .custom-file + .form-control,
      .custom-file + .custom-select,
      .custom-file + .custom-file,
      .select2-custom .select2-selection {
        margin-left: 0;
        margin-top: -$input-border-width;
      }
    }
  }
}