/*------------------------------------
  Avatar Ratio
------------------------------------*/

@use "sass:math";

.avatar.avatar-4by3 {
  width: math.div($avatar-width * 4, 3);
}

.avatar-xss.avatar-4by3 {
  width: math.div($avatar-width-xss * 4, 3);
}

.avatar-xs.avatar-4by3 {
  width: math.div($avatar-width-xs * 4, 3);
}

.avatar-sm.avatar-4by3 {
  width: math.div($avatar-width-sm * 4, 3);
}

.avatar-lg.avatar-4by3 {
  width: math.div($avatar-width-lg * 4, 3);
}

.avatar-xl.avatar-4by3 {
  width: math.div($avatar-width-xl * 4, 3);
}

.avatar-xxl.avatar-4by3 {
  width: math.div($avatar-width-xxl * 4, 3);
}