/* You can add global styles to this file, and also import other style files */
@import "assets/theme/scss/theme";

@import "../node_modules/@fortawesome/fontawesome-pro/css/all.css";

@import "assets/theme/icon-set/style.css";

// Color
.blue {
  color: darken($blue, 10%);
}
.red {
  color: darken($red, 10%);
}
.yellow {
  color: darken($yellow, 10%);
}
.green {
  color: darken($teal, 10%);
}
.orange {
  color: darken($orange, 10%);
}

a {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.p-component {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
}

/** Custom CSS for notification modifiers **/
.simple-notification {
  padding: 0px !important;
}
.simple-notification .sn-html {
  padding: 0 !important;
}

.toast {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

// sovrascrivo il tema non potendo impostare la classe nav-fill nel component ngx-bootstrap tabset
tabset > ul.nav-justified {
  display: flex !important;
}

tabset .tab-content {
  display: none;
}

tabset .nav-item .nav-icon {
  font-size: 1rem;
  margin-right: map-get($spacers, 2);
}

.hs-unfold-content {
  opacity: 1;
}

.card-is-loading {
  filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  -moz-filter: blur(5px);
  -webkit-filter: blur(5px);
}

.card-header .tab-container .nav-segment {
  border-radius: 0;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.card-datatable-alert .alert {
  margin-bottom: 0;
}

.card-datatable-menubar .p-menubar {
  padding: 0;
  border-color: transparent;
  background-color: transparent;
}

.card-datatable-menubar .p-menubar.btn-selected-row-datatable {
  border: 0.0625rem solid transparent;
  border-color: #377dff;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #377dff;
}

.card-datatable-menubar p-menubar.disabled .p-menubar.btn-selected-row-datatable {
  color: #377dff;
  background-color: transparent;
}

p-menubar.disabled .p-menubar.btn-selected-row-datatable  .p-menubar-root-list > .p-menuitem > .p-menuitem-link,
p-menubar.disabled .p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon,
p-menubar.disabled .p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
p-menubar.disabled .p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text,
p-menubar.disabled .p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
p-menubar.disabled .p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon,
p-menubar.disabled .p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon,
p-menubar.disabled .p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
p-menubar.disabled .p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #377dff;
}

.p-menubar.btn-selected-row-datatable  .p-menubar-root-list > .p-menuitem > .p-menuitem-link,
.p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon,
.p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text,
.p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon,
.p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon,
.p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar.btn-selected-row-datatable .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #fff;
}

.card-datatable-menubar .p-menubar .p-submenu-list {
  width: 20rem;
}

.card-datatable-menubar
  .p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
  height: 35px;
}

*::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 0;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: rgba(193, 193, 193, 0.8313725490196079);
}

.do-not-copy-me {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.confirmation-phrase {
  font-style: italic;
  font-weight: 900;
  color: #1e2022;
}

.navbar-vertical.navbar.aside-mini-mode {
  width: 5rem;
}
.navbar-vertical.navbar.aside-mini-mode .nav-subtitle-replacer {
  display: block;
}

.navbar-vertical.navbar.aside-mini-mode .nav-subtitle {
  display: none;
}

.sidebar-detached-content.aside-mini-mode {
  margin-left: 7rem;
}

.aside-mini-dropdown-box {
  margin-left: 1rem;
  min-width: 12rem;
}

.tieredMenuSidebar.p-tieredmenu {
  margin-left: 276px;
  border-radius: 0.75rem;
  padding: 1rem 0;
}

.tieredMenuSidebar.p-tieredmenu .p-submenu-list {
  margin-left: 8px;
  border-radius: 0.75rem;
  padding: 1rem 0;
}

.p-dropdown .p-dropdown-clear-icon {
  background: white;
}

.p-tree-wrapper {
  max-height: 300px !important;
}

.bg-card-header {
  background-color: #8c98a4 !important;
}

.p-dataview-header {
  background: none !important;
  padding: 0px !important;
  border: none !important;
}

.p-paginator-bottom {
  border: none !important;
  background: none !important;
}

.p-dataview-content {
  padding: 0.5rem !important;
  border: none !important;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: none !important;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  background: #dee2e6 !important;
  color: #6c757d !important;
}

.p-datatable-custom table {
  table-layout: auto !important;
}

.p-datatable-custom .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-custom .p-datatable-header {
  background: none;
  border: none;
  padding: 0px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-datatable-custom .p-datatable-header > div {
  margin: 0px;
}

.p-datatable-custom .p-datatable-thead > th {
  background: red;
}

.p-datatable-custom .p-datatable-thead > tr > th {
  background: #f8fafd;
  border-color: rgba(231, 234, 243, 0.7);
}

.p-datatable-custom .p-datatable-tbody > tr > td {
  border-color: transparent;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #ced4da;
}

// Datatable Prime NG

.p-datatable .p-datatable-tbody > tr {
  height: 60px;
}

.p-paginator .p-paginator-current {
  border: none;
  color: #677788;
}

.p-datatable .p-datatable-thead > tr > th {
  color: inherit;
}

// Search Dropdown Menu

.showSearchDropdownMenu {
  position: absolute;
  display: block !important;
  min-width: 336px;
}

#headerSearchInput {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.searchIndexDataMatch b {
  color: $blue;
}

.card-material {
  max-width: 200px;
}

.card-material.youtube {
  background: #ff9494;
}

.card-material.form {
  background: #949fff;
}

.card-material.meet {
  background: #038f8183;
}

.card.card-material.meet > .row > .card-material-img > img {
  border: none !important;
}

.card-material.link {
  background: #94d4ff;
}

.card-material.drive {
  background: #ffe494;
}

.card-material-img {
  margin: auto 0;
  max-width: 55px;
  max-height: 50px;
  padding-left: 8px !important;
  border-radius: 3rem;
}

.card-material-img > img {
  border-radius: 3px;
  max-height: 45px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
}

.custom-p-tree > .p-tree {
  border-color: transparent !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.card-header:first-child.collapsed {
  border-radius: 0.6875rem 0.6875rem 0.6875rem 0.6875rem;
}

.p-selectbutton .p-button {
  border: 1px solid #e7eaf3;
  border-radius: 0.25rem;
  color: #677788;
  background-color: #fff;
}

.p-selectbutton .p-button.p-highlight {
  background: #377dff;
  border-color: #377dff;
  color: #fff;
}

.picker-dialog, .picker-dialog-bg {
  z-index: 2000 !important; // GOOGLE DRIVE per farlo andare sopra le modali
}
