/*------------------------------------
  Blockquote
------------------------------------*/

.blockquote {
  font-size: $blockquote-font-size;
  border-left: $blockquote-border-left-width $blockquote-border-left-type $blockquote-border-left-color;
  padding-left: $blockquote-padding-x;
  margin-bottom: 0;
}

.blockquote-sm {
  font-size: $blockquote-sm-font-size;
  padding-left: $blockquote-sm-padding-x;
}

.blockquote-footer {
  margin-top: $blockquote-footer-margin-y;
}