// user-variables.scss
// Use this to overwrite Bootstrap and Front variables

// Colori Google
// #EA4335 - Rosso
// #FBBC05 - Giallo
// #34A853 - Verde
// #4285F4 - Blu
// #5F6368 - Grigio

// // Grays
// $gray-100:                                                  #f9fafc;
// $gray-200:                                                  #f8fafd;
// $gray-300:                                                  #e7eaf3;
// $gray-400:                                                  #bdc5d1;
// $gray-500:                                                  #97a4af;
// $gray-600:                                                  #8c98a4;
// $gray-700:                                                  #677788;
// $gray-800:                                                  #71869d;
// $gray-900:                                                  #1e2022;

// // Colors
// $white:                                                     #fff;
// $dark:                                                      #132144;
$blue:                                                      #4285F4;
$teal:                                                      #34A853;
$cyan:                                                      #00eaff;
$yellow:                                                    #FBBC05;
$red:                                                       #EA4335;
$orange:                                                    #E47A25;
// $facebook:                                                  #3b5998;
// $google:                                                    #d14130;
// $twitter:                                                   #1da1f2;
// $instagram:                                                 #3f729b;
// $github:                                                    #24292e;

// // Theme Colors
// $primary:                                                   $blue;
// $secondary:                                                 $gray-800;
$secondary:                                                 #5F6368;
// $success:                                                   $teal;
// $info:                                                      $cyan;
// $warning:                                                   $yellow;
// $danger:                                                    $red;



// $font-family-base:                                          "Open Sans", sans-serif !default;

// $font-size-base:                                            .875rem !default; // Assumes the browser default, typically 16px
// $font-size-xs:                                              .75rem !default;
// $font-size-sm:                                              .8125rem !default;
// $font-size-lg:                                              1rem !default;

// $line-height-base:                                          1.6 !default;
// $line-height-sm:                                            1.5 !default;

// $font-weight-normal:                                        400 !default;
// $font-weight-bold:                                          600 !default;

// $h1-font-size:                                              $font-size-base * 1.6125 !default;
// $h2-font-size:                                              $font-size-base * 1.5 !default;
// $h3-font-size:                                              $font-size-base * 1.3125 !default;
// $h4-font-size:                                              $font-size-base * 1.125 !default;
// $h5-font-size:                                              $font-size-base !default;
// $h6-font-size:                                              $font-size-base * .875 !default;

// $headings-line-height:                                      1.4 !default;
// $headings-font-weight:                                      $font-weight-bold !default;

// $display1-size:                                             $font-size-base * 5 !default;
// $display2-size:                                             $font-size-base * 4 !default;
// $display3-size:                                             $font-size-base * 3.5 !default;
// $display4-size:                                             $font-size-base * 2 !default;

// $display1-weight:                                           $font-weight-bold !default;
// $display2-weight:                                           $font-weight-bold !default;
// $display3-weight:                                           $font-weight-bold !default;
// $display4-weight:                                           $font-weight-bold !default;
// $display-line-height:                                       1.4 !default;

// $outline:                                                   5px auto -webkit-focus-ring-color !default;

// // Colors
// $body-color:                                                $gray-700 !default;
// $headings-color:                                            $gray-900 !default;
// $link-color:                                                $primary !default;
// $link-hover-color:                                          darken($link-color, 15%) !default;
// $active-color:                                              $primary !default;
// $disabled-color:                                            $gray-600 !default;
// $text-muted:                                                $gray-600 !default;
// $white-color-70:                                            rgba($white, .7) !default;
// $white-hover-color:                                         $white !default;
// $link-hover-decoration:                                     none !default;

// // Backgrounds
// $secondary-bg-color:                                        transparent !default;
// $secondary-hover-bg-color:                                  rgba($primary, .1) !default;
// $secondary-active-bg-color:                                 $secondary-hover-bg-color !default;
// $active-bg-color:                                           $primary !default;
// $disabled-bg-color:                                         rgba($gray-300, .5) !default;
// $light-bg-color:                                            $gray-300 !default;
// $overlay-soft-bg-color:                                     rgba($dark, .25) !default;

// // Shadows
// $box-shadow:                                                0px .375rem 1.5rem 0px rgba($gray-600, .125) !default;
// $box-shadow-sm:                                             0px 6px 12px rgba($gray-600, .075) !default;
// $box-shadow-sm-hover:                                       0px 6px 12px rgba($gray-600, .25) !default;
// $box-shadow-lg:                                             0px 10px 40px 10px rgba($gray-600, .175) !default;
// $box-shadow-primary-sm:                                     0px 0px 35px rgba($primary, .125) !default;
// $box-shadow-primary-lg:                                     0px 0px 50px rgba($primary, .4) !default;
// $box-shadow-soft:                                           0px 3px 6px 0px rgba($gray-600, .25) !default;
// $box-shadow-vertical-right:                                 -5px 0px 12px rgba($gray-700, .1) !default;
// $box-shadow-vertical-left:                                  5px 0px 12px rgba($gray-700, .1) !default;

// // Borders
// $border-width:                                              .0625rem !default;
// $border-type:                                               solid !default;
// $border-color:                                              $gray-300 !default;
// $border-active-color:                                       $primary !default;
// $border-active-light-color:                                 $white !default;

// // Border Radius
// $border-radius:                                             .3125rem !default;
// $border-radius-sm:                                          .25rem !default;
// $border-radius-lg:                                          .75rem !default;
// $border-radius-pill:                                        6.1875rem !default;
// $border-radius-rounded:                                     50% !default;

// // Transitions
// $transition-timing:                                         .3s !default;
// $transition-timing-sm:                                      .2s !default;
// $transition-timing-md:                                      .4s !default;
// $transition-function:                                       ease-in-out !default;

// // Others
// $letter-spacing:                                            .03125rem !default;

// $hr-border-color:                                           $gray-300 !default;

// $lead-font-weight:                                          $font-weight-normal !default;

// $dt-font-weight:                                            $font-weight-bold !default;

// $mark-bg:                                                   $secondary-hover-bg-color !default;

// $kbd-color:                                                 $headings-color !default;
// $kbd-bg:                                                    $light-bg-color !default;

// $dt-color:                                                  $headings-color !default;
// $dd-margin-y:                                               .75rem !default;

