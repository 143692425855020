/*------------------------------------
  Range Slider
------------------------------------*/

.range-slider-custom {
  height: $range-slider-height;

  .irs {
    height: $range-slider-height;
  }

  .irs-line {
    height: $range-slider-bar-height;
  }

  .irs-bar {
    height: $range-slider-bar-height;
    background-color: $range-slider-bar-bg-color;
  }

  .irs-handle {
    width: $range-slider-handle-width;
    height: $range-slider-handle-height;
    top: $range-slider-handle-height * 0.5;
    background-color: $range-slider-handle-bg-color;
    cursor: pointer;
    @include border-radius($range-slider-handle-border-radius);
    box-shadow: $range-slider-handle-box-shadow;

    i:first-child {
      display: none;
    }

    &.state_hover {
      transform: scale($range-slider-handle-hover-scale-value);
    }
  }

  .irs-from,
  .irs-to,
  .irs-single {
    top: $range-slider-single-top-offset;
    display: inline-block;
    min-width: $range-slider-single-min-width;
    background-color: $range-slider-single-bg-color;
    color: $range-slider-single-color;
    font-size: $range-slider-single-text-size;
    text-shadow: none;
    text-align: center;
    box-shadow: $range-slider-handle-box-shadow;
    @include border-radius($range-slider-single-border-radius);
    padding: $range-slider-single-padding-y $range-slider-single-padding-x;

    &::before {
      border-top-color: $range-slider-single-bg-color;
    }
  }
}

/* Grid */
.range-slider-grid {
  .irs-grid-pol {
    top: $range-slider-grid-pol-top-offset;
    height: $range-slider-grid-pol-height;
    background-color: $range-slider-grid-bg-color;

    &.small {
      display: none;
    }
  }

  .irs-grid-text {
    top: $range-slider-grid-text-top-offset;
    font-size: $range-slider-grid-pol-font-size;
  }
}