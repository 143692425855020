/*------------------------------------
  Text Colors
------------------------------------*/

.text-dark {
  color: $gray-900 !important;

  &[href]:hover {
    color: $primary !important;
  }
}

.text-secondary {
  &[href]:hover {
    color: $primary !important;
  }
}

.text-muted,
.text-body {
	&[href]:hover {
		color: $link-hover-color !important;
	}
}

.text-white-70 {
  color: $white-color-70;

  &[href]:hover {
    color: $white-hover-color;
  }
}

a:hover {
  .text-hover-primary {
    color: $link-hover-color !important;
  }
}

.text-warning {
  color: darken($warning, 20%) !important;
}