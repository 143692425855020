/*------------------------------------
  Typography
------------------------------------*/

.text-cap {
  display: block;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing;
}

.font-size-sm {
  font-size: $font-size-sm;
}