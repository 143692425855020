/*------------------------------------
  Step Divider
------------------------------------*/

.step-divider {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  height: $step-divider-height;
  font-weight: $step-divider-font-weight;

  &::after {
    position: absolute;
    top: $step-divider-height + $step-padding-x * 0.5;
    left: $step-icon-width * 0.5;
    height: calc(100% - #{$step-divider-height + $step-padding-x * 0.5 - $step-padding-x});
    border-left: $step-border-width $step-border-type $step-border-color;
    content: "";
  }
}