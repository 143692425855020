/*------------------------------------
  Opacity
------------------------------------*/

.opacity-xs {
  opacity: .2;
}

.opacity-sm {
  opacity: .4;
}

.opacity {
  opacity: .6;
}

.opacity-lg {
  opacity: .8;
}