/*------------------------------------
  Avatar Uploader
------------------------------------*/

.avatar-uploader {
  cursor: pointer;
  display: inline-block;
  transition: $avatar-uploader-input-transition;
  margin-bottom: 0;

  &:hover {
    border-color: $avatar-uploader-avatar-border-hover-color;
  }
}

.avatar-uploader-input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: $avatar-uploader-input-overlay-bg-color;
  @include border-radius($avatar-uploader-input-border-radius);
  transition: $avatar-uploader-input-transition;
}

.avatar-uploader-trigger {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  @include border-radius($avatar-uploader-input-border-radius);
}

.avatar-uploader-icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: $avatar-uploader-icon-color;
  background-color: $avatar-uploader-icon-bg-color;
  @include border-radius($avatar-uploader-icon-border-radius);
  transition: $avatar-uploader-icon-transition;
}

.avatar-uploader:hover {
  .avatar-uploader-icon {
    color: $avatar-uploader-icon-hover-color;
    background-color: $avatar-uploader-icon-bg-hover-color;
  }
}

// Sizes
.avatar-lg {
  .avatar-uploader-icon {
    font-size: $avatar-lg-uploader-icon-font-size;
    width: $avatar-lg-uploader-icon-width;
    height: $avatar-lg-uploader-icon-height;
  }
}

.avatar-xl {
  .avatar-uploader-icon {
    font-size: $avatar-xl-uploader-icon-font-size;
    width: $avatar-xl-uploader-icon-width;
    height: $avatar-xl-uploader-icon-height;
  }
}

.avatar-xxl {
  .avatar-uploader-icon {
    width: $avatar-xxl-uploader-icon-width;
    height: $avatar-xxl-uploader-icon-height;
  }
}