/*------------------------------------
  Step Avatar
------------------------------------*/

.step {
  .step-avatar {
    font-size: $step-avatar-font-size;
    font-weight: $step-avatar-font-weight;
    width: $step-avatar-width;
    height: $step-avatar-height;
    @include border-radius($step-avatar-border-radius);
    margin-right: $step-avatar-margin-x;
  }

  .step-avatar-img {
    max-width: 100%;
    height: auto;
    @include border-radius($step-avatar-border-radius);
  }

  .step-avatar::after {
    position: absolute;
    top: $step-avatar-height + $step-padding-x * 0.5;
    left: $step-avatar-width * 0.5;
    height: calc(100% - #{$step-avatar-height + $step-padding-x * 0.5 - $step-padding-x});
    border-left: $step-border-width $step-border-type $step-border-color;
    content: "";
  }
}